import gql from "graphql-tag";

export const queryScheduleAppointment = gql`
  query ScheduleAppointment {
    heading: contentFragment(
      group: "customer-service"
      key: "schedule-appointment-heading"
    ) {
      text
    }
    title: contentFragment(
      group: "customer-service"
      key: "schedule-appointment-title"
    ) {
      text
    }
    members: contentFragment(
      group: "customer-service"
      key: "schedule-appointment-rh-members"
    ) {
      text
    }
    placeholder: contentFragment(
      group: "customer-service"
      key: "schedule-appointment-placeholder"
    ) {
      text
    }
    success: contentFragment(
      group: "customer-service"
      key: "schedule-appointment-success"
    ) {
      text
    }
  }
`;

export const queryScheduleTradeAppointment = gql`
  query ScheduleTradeAppointment {
    heading: contentFragment(
      group: "customer-service"
      key: "schedule-trade-appointment-heading"
    ) {
      text
    }
    title: contentFragment(
      group: "customer-service"
      key: "schedule-trade-appointment-title"
    ) {
      text
    }
    placeholder: contentFragment(
      group: "customer-service"
      key: "schedule-trade-appointment-placeholder"
    ) {
      text
    }
    success: contentFragment(
      group: "customer-service"
      key: "schedule-trade-appointment-success"
    ) {
      text
    }
    successHeading: contentFragment(
      group: "customer-service"
      key: "schedule-trade-appointment-success-head"
    ) {
      text
    }
  }
`;

export const queryReturnContentFragment = gql`
  query ContentFragment($key: String = "") {
    contentFragment(group: "returns-pages", key: $key) {
      text
    }
  }
`;

export const queryReturnContentContentWindowFragment = gql`
  query ContentCustomWindowResponse(
    $group: String = "custom-window-design-consultation"
  ) {
    contentCustomWindowResponse(group: $group) {
      header
      paragraph
      placeholder
      subheader
      successmessage
      successtitle
    }
  }
`;

export const queryReturnContentModalFragment = gql`
  query ContentFragment($group: String = "", $key: String = "") {
    contentFragment(group: $group, key: $key) {
      text
    }
  }
`;

export const queryReturnPolicyContentFragment = gql`
  query contentFragment(
    $key: String = ""
    $currencyCode: String = "USA"
    $siteId: String = ""
  ) {
    contentFragment(
      group: "customer-service"
      key: $key
      siteId: $siteId
      currencyCode: $currencyCode
    ) {
      text
    }
  }
`;
