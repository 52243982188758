import gql from "graphql-tag";

export const querySessionConf = gql`
  query SessionConf {
    sessionConf {
      sessionConfirmation
      sessionId
    }
  }
`;
