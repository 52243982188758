import gql from "graphql-tag";

export const queryFullSkuProduct = gql`
  query FullSkuProductQuery($productId: String!, $fullSkuId: String!) {
    product(productId: $productId) {
      __typename
      fullSkuSwatchIds(fullSkuId: $fullSkuId)
    }
  }
`;
