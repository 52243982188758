import gql from "graphql-tag";
import { PromoDisplayFields } from "graphql-client/fragments/promo-display";

export const queryPromoDisplay = gql`
  query PromoDisplay(
    $id: String
    $deviceType: String = "mobile"
    $siteId: String
    $countryCode: String
    $locale: String
  ) {
    promoDisplay(
      id: $id
      deviceType: $deviceType
      siteId: $siteId
      countryCode: $countryCode
      locale: $locale
    ) {
      ...PromoDisplayFields
    }
  }
  ${PromoDisplayFields}
`;
