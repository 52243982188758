import gql from "graphql-tag";

export const queryProductSwatchImage = gql`
  query ProductSwatchImage(
    $productId: String!
    $optionIds: [String!]
    $siteId: String
    $locale: String
    $trimOptionIds: [String!]
    $fullskuId: String
  ) {
    productSwatchImage(
      productId: $productId
      optionIds: $optionIds
      siteId: $siteId
      locale: $locale
      trimOptionIds: $trimOptionIds
      fullskuId: $fullskuId
    ) {
      imageUrl
    }
  }
`;
