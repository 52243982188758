import gql from "graphql-tag";
import { Membership } from "graphql-client/fragments/membership";

export const cancelMembershipMutation = gql`
  mutation CancelMembership(
    $userId: String
    $region: String
    $cancellationReason: String
    $membershipId: String
  ) {
    cancelMembership(
      userId: $userId
      region: $region
      cancellationReason: $cancellationReason
      membershipId: $membershipId
    ) {
      ...Membership
    }
  }
  ${Membership}
`;

export const restoreMembershipMutation = gql`
  mutation RestoreMembership(
    $contactId: String
    $membershipId: String
    $region: String
    $membershipUuid: String
    $userId: String
  ) {
    restoreMembership(
      contactId: $contactId
      membershipId: $membershipId
      region: $region
      membershipUuid: $membershipUuid
      userId: $userId
    ) {
      ...Membership
    }
  }
  ${Membership}
`;
