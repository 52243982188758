import gql from "graphql-tag";

export const getMembershipInfoForSession = gql`
  query GetMembershipInfoForSession($country: String) {
    getMembershipInfoForSession(country: $country) {
      membershipSkuIsOnCart
      userHasActiveMembership
      userHasCancelledMembership
      userHasExpiredMembership
      currencyCode
      membershipId
    }
  }
`;
