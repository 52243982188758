import gql from "graphql-tag";
export const Sku = gql`
  fragment Sku on ProductSku {
    __typename
    info {
      name
      skuPriceInfo {
        currencySymbol
        listPrice
        salePrice
        memberPrice
        tradePrice
        contractPrice
        memberOriginalPrice
        nextgenDriven
        onSale
        showMemberPrice
        customProductErrorCode
        sendCwCustomProductCode
      }
      hasCasingSkus
      casingProduct
      replacementCushionProduct
    }

    delivery {
      postalCode
      deliveryEstimate
      shippingSurcharge
      shippingSurchargeAmount
      freightExemptMessage
      shipVia
      shippingSurcharge
      unlimitedFurnitureDelivery
      currency
      freightExempt
      isSwatch
      deliveryEstimateStatus
      deliveryStateMessage
    }
    restrictions {
      spo
      giftCertificate
      serviceSku
      returnPolicyMessage
      preBillMessage
      additionalMessages {
        curbsideMessage
        assemblyMessage
        giftCardMessage
        railroadMessage
        mattressFeeMessage
        cancellableMessage
        finalSaleMessage
      }
      countryRestrictions
    }
    fulfillmentEta {
      fullSkuId
      atgSkuId
      postalCode
      inventoryRemaining
      inventoryMessage
      itemsInStockMessage
      lineItemMessage
      needPostalCode
      preOrder
      lineType
    }
  }
`;

export const SkuRestrictions = gql`
  fragment SkuRestrictions on ProductSku {
    __typename
    info {
      name
      longDescription
      imageUrl
      maxOrderQty
      skuPriceInfo {
        currencySymbol
        listPrice
        salePrice
        memberPrice
        nextgenDriven
        tradePrice
        contractPrice
        onSale
        onClearance
        showMemberPrice
        customProductErrorCode
        customProductErrorMsg
      }
      canadaShippable
      dropship
    }
    delivery {
      postalCode
      needPostalCode # Unused
      deliveryEstimateStatus # Unused
      deliveryEstimate
      freightExempt # Unused
      shippingSurcharge
      shippingSurchargeAmount
      freightExemptMessage
      deliveryStateMessage # Unused
      lineId # Unused
    }
    restrictions {
      spo
      returnPolicyMessage
      preBillMessage
      countryRestrictions
    }
    inventory {
      inventoryRemaining
    }
  }
`;

export const SkuInventory = gql`
  fragment SkuInventory on ProductSku {
    __typename
    inventory {
      lineId # Unused
      fullSkuId
      atgSkuId
      postalCode
      inventoryCode # Unused
      inventoryStatus # Unused
      inventoryRemaining
      inventoryMessage
      itemsInStockMessage
      lineItemMessage
      needPostalCode # Unused
      postalCodeSpecific #Unused
      preOrder
      dateString
      failureMessage
    }
  }
`;
