import gql from "graphql-tag";

export const mutationRequestTradeService = gql`
  mutation RequestTradeService(
    $firstName: String!
    $lastName: String!
    $tradeAccountNumber: String
    $phone: String!
    $email: String!
    $postalcode: String!
    $preferredContact: String!
    $description: String!
    $country: String
    $prefix: String
  ) {
    requestTradeService(
      firstName: $firstName
      lastName: $lastName
      tradeAccountNumber: $tradeAccountNumber
      phone: $phone
      email: $email
      postalcode: $postalcode
      preferredContact: $preferredContact
      description: $description
      country: $country
      prefix: $prefix
    ) {
      statusCode
    }
  }
`;
