import gql from "graphql-tag";

export const queryShopByRoom = gql`
  query ShopByRoom(
    $id: String
    $siteId: String
    $countryCode: String
    $locale: String
  ) {
    shopByRoom(
      id: $id
      siteId: $siteId
      countryCode: $countryCode
      locale: $locale
    ) {
      id
      name
      title
    }
  }
`;
