import gql from "graphql-tag";
import { PromoDisplayFields } from "graphql-client/fragments/promo-display";

export const queryPromoDisplayContent = gql`
  query PromoDisplayContent(
    $context: String!
    $deviceType: String = "mobile"
    $siteId: String!
  ) {
    promoDisplayContent(
      context: $context
      deviceType: $deviceType
      siteId: $siteId
    ) {
      ...PromoDisplayFields
    }
  }
  ${PromoDisplayFields}
`;
