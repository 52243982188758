import gql from "graphql-tag";
import {
  ZeroResults,
  AdjustedSearch,
  InstructionResultList,
  OtherInfo,
  ResultTab,
  RefinementMenu,
  ProductCarousel,
  ATGCollectionGalleryInfo
} from "graphql-client/fragments/search";
import { SaleMemberInfoFields } from "graphql-client/fragments/pg-sale-member-info";
import { PromoDisplayFields } from "graphql-client/fragments/promo-display";

export const queryTypeahead = gql`
  query AutoSuggest($ntt: String!, $country: String) {
    autoSuggest(ntt: $ntt, country: $country) {
      resultList {
        records {
          recordType
          product {
            displayName
            repositoryId
          }
        }
      }
      categoryList {
        totalNumResults
        categories {
          navigationState
          label
          properties {
            parentCategoryName
            isCrossBrand
          }
          host
        }
      }
      promo {
        promotionDisplay_imageUrl_s
        promotionDisplay_name_t
      }
      searchQueryId
      filterQueries
    }
  }
`;

export const queryInfiniteScroll = gql`
  query InfiniteScroll(
    $ntt: String!
    $contentType: String
    $n: String
    $nrpp: Int
    $ns: String
    $no: Int
    $country: String
    $currencyCode: String
    $userType: String
    $site: String
  ) {
    infiniteScroll(
      ntt: $ntt
      contentType: $contentType
      n: $n
      nrpp: $nrpp
      ns: $ns
      no: $no
      country: $country
      currencyCode: $currencyCode
      userType: $userType
      site: $site
    ) {
      stocked
      adapter
      profileCookie
      resultList {
        lastRecNum
        firstRecNum
        recsPerPage
        totalNumRecs
        records {
          recordType
          product {
            newPdpLayout
            conciergeOnlyForNewPDP
            altImageUrl
            imageUrl
            imageFlip
            displayName
            anchor
            repositoryId
            colorizable
            removeFromBrowse
            skuPriceInfo {
              fullSkuId
              currencySymbol
              listPrice
              salePrice
              memberPrice
              tradePrice
              contractPrice
              nextgenDriven
              onSale
              onClearance
              showMemberPrice
              skuOptions {
                id
                optionType
                label
              }
            }
            priceInfo {
              currencySymbol
              isCustomProduct
              isRetail
              isUnavailable
              priceFilter
              isSale
              priceMessage
              priceRange {
                allOnSale
                assetId
                currencyApplied
                highestFullSkuId
                highestPaidFullSkuId
                lowestFullSkuId
                lowestPaidFullSkuId
                noPriceRange
                onClearance
                onSale
                onSaleAndOnClearance
                previewMode
                salePriceListId
                tradeBest
              }
              rangeType
              showMemberPrice
              strikePriceLabel
              priceMessagePrice
              listPrices
              listPriceLabel
              salePrices
              salePriceLabel
              memberPrices
              memberPriceLabel
            }
            swatchInfo {
              swatchesToDisplay {
                displayName
                imageRef
                imageUrl
                swatchId
                displayName
              }
              swatchProductImages {
                imageUrl
                swatchId
              }
              numAdditionalSwatchesAvailable
              numAdditionalSaleSwatchesAvailable
            }
          }
          sku {
            fullSkuId
          }
        }
        sortOptions {
          navigationState
          label
          selected
        }
      }
      instructionResultList {
        firstRecNum
        lastRecNum
        recsPerPage
        totalNumRecs
        name
        records {
          productDisplayName
          link
          label
        }
      }
    }
  }
`;

export const querySearch = gql`
  query Search(
    $ntt: String!
    $contentType: String
    $n: String
    $nrpp: Int
    $ns: String
    $no: Int
    $country: String
    $currencyCode: String
    $userType: String
    $site: String
    $infiniteScroll: Boolean
    $categoryId: String
    $sale: Boolean
    $locale: String
    $nextgenDriven: Boolean
    $maxSearchTab: Boolean
    $isSearchBoxUse: Boolean
    $activeTab: String
    $isClearAll: Boolean
  ) {
    search(
      ntt: $ntt
      contentType: $contentType
      n: $n
      nrpp: $nrpp
      ns: $ns
      no: $no
      country: $country
      currencyCode: $currencyCode
      userType: $userType
      site: $site
      infiniteScroll: $infiniteScroll
      categoryId: $categoryId
      sale: $sale
      locale: $locale
      nextgenDriven: $nextgenDriven
      maxSearchTab: $maxSearchTab
      isSearchBoxUse: $isSearchBoxUse
      activeTab: $activeTab
      isClearAll: $isClearAll
    ) {
      adapter
      profileCookie
      redirectUrl
      stocked
      zeroResults {
        ...ZeroResultFields
      }
      adjustedSearches {
        ...AdjustedSearchFields
      }
      dynamicFacets {
        name
        values
      }
      resultList {
        lastRecNum
        firstRecNum
        recsPerPage
        totalNumRecs
        records {
          recordType
          product {
            newPdpLayout
            conciergeOnlyForNewPDP
            altImageUrl
            imageUrl
            galleryDescription
            alternateImages {
              imageUrl
            }
            displaySwatch
            displayName
            anchor
            repositoryId
            colorizable
            customProduct
            skuOptiondata
            imageFlip
            newProduct
            emptyProduct
            metaProductComponent
            removeFromBrowse
            pgCrop
            pgCropRules
            rhr
            pdpImageLayout
            skuPriceInfo {
              fullSkuId
              currencySymbol
              listPrice
              salePrice
              memberPrice
              tradePrice
              contractPrice
              nextgenDriven
              onSale
              onClearance
              showMemberPrice
              skuOptions {
                id
                optionType
                label
              }
            }
            metaProduct
            priceInfo {
              currencySymbol
              isCustomProduct
              listPrices
              memberPrices
              overridePriceLabel
              skulowestMemberPrice
              nextGenDriven
              nextGenDrivenOnSale
            }
            swatchInfo {
              swatchesToDisplay {
                imageRef
                imageUrl
                swatchId
                displayName
              }
              swatchProductImages {
                imageUrl
                swatchId
              }
              numAdditionalSwatchesAvailable
              numAdditionalSaleSwatchesAvailable
            }
            galleryDescription
            percentSaleSkus
            memberSavingsMin
            memberSavingsMax
            lowestskuPriceInfo {
              skuListSaleprice
              skuMemberSalePrice
              skuSalePrice
            }
            uxAttributes {
              productType
              triggerSwatchImage
              giftCert
              membershipProduct
            }
          }
          sku {
            fullSkuId
          }
        }
        sortOptions {
          navigationState
          label
          selected
        }
      }
      resultTabs {
        ...ResultTab
      }
      tabInfo {
        tabs {
          site
          numResults
          tabNo
          displayName
          activeTab
        }
      }
      booleanFacets {
        ...RefinementMenu
      }
      refinementMenus {
        ...RefinementMenu
      }
      otherInfo {
        ...OtherInfo
      }
      instructionResultList {
        ...InstructionResultList
      }
      productCarousel {
        ...ProductCarousel
      }
      promoDisplay {
        ...PromoDisplayFields
      }
      searchQueryId
      filterQueries
      skuSearch
      q
      sku_siteId
      ruleExecuted
      saleMemberInfo {
        ...SaleMemberInfoFields
      }
      ATGCollectionGalleryInfo {
        collectionGallery {
          ...ATGCollectionGalleryInfo
        }
      }
      inStockFlow
    }
  }
  ${PromoDisplayFields}
  ${ZeroResults}
  ${AdjustedSearch}
  ${InstructionResultList}
  ${OtherInfo}
  ${ResultTab}
  ${RefinementMenu}
  ${ProductCarousel}
  ${SaleMemberInfoFields}
  ${ATGCollectionGalleryInfo}
`;
