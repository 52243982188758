import gql from "graphql-tag";

export const queryAboveNavPromo = gql`
  query AboveNavPromo(
    $siteId: String!
    $deviceType: String = "mobile"
    $currencyCode: String = "USA"
    $countryCode: String!
  ) {
    aboveNavPromo(
      siteId: $siteId
      deviceType: $deviceType
      currencyCode: $currencyCode
      countryCode: $countryCode
    ) {
      text
    }
  }
`;
