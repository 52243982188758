import gql from "graphql-tag";
import {
  LineItem,
  LineItemSku,
  LineItemInventory,
  LineItemSkuDetails,
  LineItemLite,
  LineItemLiteV3
} from "graphql-client-product/fragments/product-line-item";

export const queryLineItem = gql`
  query LineItemQuery(
    $productId: String!
    $selectedOptionIds: [String!]
    $selectedTrimOptionIds: [String!]
    $userType: String
    $postalCode: String
    $currencyCode: String
    $fullSkuId: String
    $monogrammable: Boolean
    $isMonogrammed: Boolean
    $qty: Int
    $filter: String
    $width: Float
    $length: Float
    $diameter: Float
    $continuousLoop: Boolean
    $motorized: Boolean
    $isCustom: Boolean
    $siteId: String
    $measureSystem: String
    $locale: String
    $nextGenDriven: Boolean
    $shouldFetchSku: Boolean
    $shouldFetchCustomProductOptions: Boolean
    $customType: String
  ) {
    productLineItem(
      customType: $customType
      productId: $productId
      selectedOptionIds: $selectedOptionIds
      selectedTrimOptionIds: $selectedTrimOptionIds
      postalCode: $postalCode
      currencyCode: $currencyCode
      fullSkuId: $fullSkuId
      userType: $userType
      monogrammable: $monogrammable
      isMonogrammed: $isMonogrammed
      qty: $qty
      filter: $filter
      width: $width
      length: $length
      diameter: $diameter
      continuousLoop: $continuousLoop
      motorized: $motorized
      isCustom: $isCustom
      siteId: $siteId
      measureSystem: $measureSystem
      locale: $locale
      nextGenDriven: $nextGenDriven
      shouldFetchSku: $shouldFetchSku
      shouldFetchCustomProductOptions: $shouldFetchCustomProductOptions
    ) {
      ...LineItem
    }
  }
  ${LineItem}
`;

export const queryLineItemLite = gql`
  query LineItemQuery(
    $productId: String!
    $selectedOptionIds: [String!]
    $selectedTrimOptionIds: [String!]
    $userType: String
    $postalCode: String
    $currencyCode: String
    $fullSkuId: String
    $monogrammable: Boolean
    $isMonogrammed: Boolean
    $qty: Int
    $filter: String
    $width: Float
    $length: Float
    $diameter: Float
    $continuousLoop: Boolean
    $motorized: Boolean
    $isCustom: Boolean
    $siteId: String
    $measureSystem: String
    $locale: String
    $nextGenDriven: Boolean
    $shouldFetchSku: Boolean
    $shouldFetchCustomProductOptions: Boolean
  ) {
    productLineItem(
      productId: $productId
      selectedOptionIds: $selectedOptionIds
      selectedTrimOptionIds: $selectedTrimOptionIds
      postalCode: $postalCode
      currencyCode: $currencyCode
      fullSkuId: $fullSkuId
      userType: $userType
      monogrammable: $monogrammable
      isMonogrammed: $isMonogrammed
      qty: $qty
      filter: $filter
      width: $width
      length: $length
      diameter: $diameter
      continuousLoop: $continuousLoop
      motorized: $motorized
      isCustom: $isCustom
      siteId: $siteId
      measureSystem: $measureSystem
      locale: $locale
      nextGenDriven: $nextGenDriven
      shouldFetchSku: $shouldFetchSku
      shouldFetchCustomProductOptions: $shouldFetchCustomProductOptions
    ) {
      ...LineItemLite
    }
  }
  ${LineItemLite}
`;

//lighter version of LineItem query for fetching multi-sku restriction data
export const queryMultiSkuLineItem = gql`
  query LineItemQuery(
    $productId: String!
    $postalCode: String
    $fullSkuId: String
    $qty: Int
    $nextGenDriven: Boolean
    $siteId: String
    $countryCode: String
    $currencyCode: String
    $locale: String
  ) {
    productLineItem(
      productId: $productId
      postalCode: $postalCode
      fullSkuId: $fullSkuId
      qty: $qty
      nextGenDriven: $nextGenDriven
      siteId: $siteId
      countryCode: $countryCode
      currencyCode: $currencyCode
      locale: $locale
    ) {
      ...LineItemSku
    }
  }
  ${LineItemSku}
`;

//lighter version of LineItem query for fetching multi-sku bundle inventory data
export const queryMultiSkuLineItemInventory = gql`
  query LineItemQuery(
    $productId: String!
    $postalCode: String
    $fullSkuIds: [String!]
    $qty: Int
    $shouldFetchSku: Boolean
    $nextGenDriven: Boolean
    $siteId: String
    $countryCode: String
    $currencyCode: String
    $locale: String
  ) {
    productLineItem(
      productId: $productId
      postalCode: $postalCode
      fullSkuIds: $fullSkuIds
      qty: $qty
      shouldFetchSku: $shouldFetchSku
      nextGenDriven: $nextGenDriven
      siteId: $siteId
      countryCode: $countryCode
      currencyCode: $currencyCode
      locale: $locale
    ) {
      ...LineItemInventory
    }
  }
  ${LineItemInventory}
`;

export const queryLineItemSku = gql`
  query LineItemSkuQuery(
    $productId: String!
    $selectedOptionIds: [String!]
    $selectedTrimOptionIds: [String!]
    $userType: String
    $postalCode: String
    $currencyCode: String
    $fullSkuId: String
    $monogrammable: Boolean
    $isMonogrammed: Boolean
    $qty: Int
    $filter: String
    $width: Float
    $length: Float
    $diameter: Float
    $continuousLoop: Boolean
    $motorized: Boolean
    $isCustom: Boolean
    $siteId: String
    $measureSystem: String
    $locale: String
    $nextGenDriven: Boolean
    $shouldFetchSku: Boolean
    $shouldFetchCustomProductOptions: Boolean
    $shouldFetchImage: Boolean
    $newPdpLayout: Boolean
    $customType: String
  ) {
    lineItemSku(
      customType: $customType
      productId: $productId
      selectedOptionIds: $selectedOptionIds
      selectedTrimOptionIds: $selectedTrimOptionIds
      postalCode: $postalCode
      currencyCode: $currencyCode
      fullSkuId: $fullSkuId
      userType: $userType
      monogrammable: $monogrammable
      isMonogrammed: $isMonogrammed
      qty: $qty
      filter: $filter
      width: $width
      length: $length
      diameter: $diameter
      continuousLoop: $continuousLoop
      motorized: $motorized
      isCustom: $isCustom
      siteId: $siteId
      measureSystem: $measureSystem
      locale: $locale
      nextGenDriven: $nextGenDriven
      shouldFetchSku: $shouldFetchSku
      shouldFetchCustomProductOptions: $shouldFetchCustomProductOptions
      shouldFetchImage: $shouldFetchImage
      newPdpLayout: $newPdpLayout
    ) {
      ...LineItemSkuDetails
    }
  }
  ${LineItemSkuDetails}
`;

export const queryAllProductAvailableOptions = gql`
  query AllProductAvailableOptions(
    $productId: String!
    $selectedOptionIds: [String!]
    $selectedTrimOptionIds: [String!]
    $userType: String
    $postalCode: String
    $currencyCode: String
    $fullSkuId: String
    $monogrammable: Boolean
    $isMonogrammed: Boolean
    $qty: Int
    $filter: String
    $width: Float
    $length: Float
    $diameter: Float
    $continuousLoop: Boolean
    $motorized: Boolean
    $isCustom: Boolean
    $siteId: String
    $measureSystem: String
    $locale: String
    $nextGenDriven: Boolean
    $shouldFetchSku: Boolean
    $shouldFetchCustomProductOptions: Boolean
  ) {
    AllProductAvailableOptions(
      productId: $productId
      selectedOptionIds: $selectedOptionIds
      selectedTrimOptionIds: $selectedTrimOptionIds
      postalCode: $postalCode
      currencyCode: $currencyCode
      fullSkuId: $fullSkuId
      userType: $userType
      monogrammable: $monogrammable
      isMonogrammed: $isMonogrammed
      qty: $qty
      filter: $filter
      width: $width
      length: $length
      diameter: $diameter
      continuousLoop: $continuousLoop
      motorized: $motorized
      isCustom: $isCustom
      siteId: $siteId
      measureSystem: $measureSystem
      locale: $locale
      nextGenDriven: $nextGenDriven
      shouldFetchSku: $shouldFetchSku
      shouldFetchCustomProductOptions: $shouldFetchCustomProductOptions
    ) {
      productId
      priceInfo {
        minMemberPrice
        maxMemberPrice
        minListPrice
        maxListPrice
        minSalePrice
        maxSalePrice
        minTradePrice
        maxTradePrice
        minContractPrice
        maxContractPrice
        maxMemberListPrice
        minMemberListPrice
        leastPriceSku {
          memberPrice
          salePrice
          listPrice
          can_salePrice
          can_listPrice
          can_memberPrice
          contractPrice
          tradePrice
          memberSalePrice
        }
        leastDisplayPriceSku {
          memberPrice
          salePrice
          listPrice
          can_salePrice
          can_listPrice
          can_memberPrice
          contractPrice
          tradePrice
          memberSalePrice
        }
      }
      stockedSwatchList
      saleSwatchList
      saleSkuList
      stockedSkuList
      has_related_swatches
      bundleComponentProducts
      conciergeStockedSkuList
      conciergeStockedSwatchList
      optionMatrix
      preselected_options
      availableOptions {
        type
        options {
          id
          type
          value
          name
          status
          usa_list_price_f
          usa_member_price_f
          usa_sale_price_f
          can_list_price_f
          can_member_price_f
          can_sale_price_f
          gbr_list_price_f
          gbr_member_price_f
          gbr_sale_price_f
        }
      }
    }
  }
`;

export const querGetSku = gql`
  query GetSku(
    $locale: String!
    $postalCode: String!
    $siteId: String!
    $productId: String!
    $currencyCode: String
    $selectedOptionIds: [String!]
    $userType: String
    $isCustom: Boolean
    $qty: Int
    $monogrammable: Boolean
    $filter: String
    $measureSystem: String
    $continuousLoop: Boolean
    $motorized: Boolean
    $width: Float
    $length: Float
    $fullSkuId: String
  ) {
    getSku(
      locale: $locale
      postalCode: $postalCode
      siteId: $siteId
      productId: $productId
      currencyCode: $currencyCode
      selectedOptionIds: $selectedOptionIds
      userType: $userType
      isCustom: $isCustom
      qty: $qty
      monogrammable: $monogrammable
      filter: $filter
      measureSystem: $measureSystem
      continuousLoop: $continuousLoop
      motorized: $motorized
      width: $width
      length: $length
      fullSkuId: $fullSkuId
    ) {
      fullSkuId
      components {
        fullSkuId
        productId
        quantity
      }
    }
  }
`;

export const queryLineItemLiteV3 = gql`
  query LineItemQueryV3(
    $productId: String!
    $selectedOptionIds: [String!]
    $selectedSwatches: [String!]
    $selectedTrimOptionIds: [String!]
    $unavailableOption: [String!]
    $userType: String
    $postalCode: String
    $currencyCode: String
    $countryCode: String
    $fullSkuId: String
    $monogrammable: Boolean
    $isMonogrammed: Boolean
    $qty: Int
    $filter: String
    $width: Float
    $length: Float
    $diameter: Float
    $continuousLoop: Boolean
    $motorized: Boolean
    $isCustom: Boolean
    $siteId: String
    $measureSystem: String
    $locale: String
    $nextGenDriven: Boolean
    $shouldFetchSku: Boolean
    $shouldFetchCustomProductOptions: Boolean
    $inStockOnly: Boolean
  ) {
    productLineItem(
      productId: $productId
      selectedOptionIds: $selectedOptionIds
      selectedSwatches: $selectedSwatches
      selectedTrimOptionIds: $selectedTrimOptionIds
      unavailableOption: $unavailableOption
      postalCode: $postalCode
      currencyCode: $currencyCode
      countryCode: $countryCode
      fullSkuId: $fullSkuId
      userType: $userType
      monogrammable: $monogrammable
      isMonogrammed: $isMonogrammed
      qty: $qty
      filter: $filter
      width: $width
      length: $length
      diameter: $diameter
      continuousLoop: $continuousLoop
      motorized: $motorized
      isCustom: $isCustom
      siteId: $siteId
      measureSystem: $measureSystem
      locale: $locale
      nextGenDriven: $nextGenDriven
      shouldFetchSku: $shouldFetchSku
      shouldFetchCustomProductOptions: $shouldFetchCustomProductOptions
      inStockOnly: $inStockOnly
    ) {
      ...LineItemLiteV3
    }
  }
  ${LineItemLiteV3}
`;
