import gql from "graphql-tag";
import { PriceRangeDisplay } from "graphql-client-product/fragments/price-range-display";

// IMPORTANT: keep this query in its own file for optimal code chunking.
export const ProductAddons = gql`
  fragment ProductAddons on ProductAddons {
    productAddonsInfo {
      id
      imageUrl
      productAddonTitle
      productAddonMessage
      productAddonDescription
      displayName
      isActive
      saleInfo {
        percentSaleSkus
        showSaleMessage
        memberSavings {
          memberSavingsMin
          memberSavingsMax
        }
      }
      priceRangeDisplay {
        ...PriceRangeDisplay
      }
      productLineItem {
        availableOptions {
          type
          sortPriority
          optionTypeId
          options {
            id
            type
            value
            sortPriority
            status
            message
            operationType
            __typename
          }
          __typename
        }
        image {
          productId
          imageUrl
          __typename
        }
        __typename
      }
      imageUrl
      __typename
    }
    __typename
  }
  ${PriceRangeDisplay}
`;
