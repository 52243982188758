import gql from "graphql-tag";

export const mutationRequestConsultation = gql`
  mutation RequestConsultation(
    $lastName: String!
    $email: String!
    $phone: String!
    $preferredContact: String!
    $description: String!
    $leadSource: String!
    $requestType: String!
    $inquiryDate: String!
    $galleryNumber: String!
    $firstName: String!
    $country: String
  ) {
    requestConsultation(
      lastName: $lastName
      email: $email
      phone: $phone
      preferredContact: $preferredContact
      description: $description
      leadSource: $leadSource
      requestType: $requestType
      inquiryDate: $inquiryDate
      galleryNumber: $galleryNumber
      firstName: $firstName
      country: $country
    ) {
      statusCode
      status
    }
  }
`;
