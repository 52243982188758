import gql from "graphql-tag";

export const queryMultiSkuComponentsV2 = gql`
  query MultiSkuCompopnents(
    $productId: String!
    $selectedOptionIds: [String!]
    $siteId: String!
    $currencyCode: String
    $countryCode: String!
    $postalCode: String!
    $locale: String!
    $qty: Float
    $userType: String
    $newLayout: Boolean
  ) {
    pullMultiSkuComponents(
      productId: $productId
      selectedOptionIds: $selectedOptionIds
      currencyCode: $currencyCode
      siteId: $siteId
      countryCode: $countryCode
      postalCode: $postalCode
      locale: $locale
      qty: $qty
      userType: $userType
      newLayout: $newLayout
    ) {
      contractPrice
      listPrice
      memberPrice
      salePrice
      tradePrice
      components {
        delivery {
          fullSkuId
          postalCode
          needPostalCode
          deliveryEstimateStatus
          deliveryEstimate
          freightExempt
          shippingSurcharge
          shippingSurchargeAmount
          freightExemptMessage
          deliveryStateMessage
          shipVia
          unlimitedFurnitureDelivery
          currency
          isSwatch
          lineId # Unused
        }
        fullSkuId
        info {
          name
          productId
          longDescription
          imageUrl
          webPurchasable
          canadaShippable
          dropship
          shipViaCode
          upc
          sts
          maxOrderQty
          hasCasingSkus
          casingProduct
          replacementCushionProduct
        }
        inventory {
          fullSkuId
          atgSkuId
          inventoryCode
          inventoryOnHand
          inventoryStatus
          inventoryRemaining
          inventoryMessage
          itemsInStockMessage
          lineItemMessage
          needPostalCode
          postalCodeSpecific
          preOrder
          dateString
          adapter
          failureMessage
        }
        restrictions {
          spo
          giftCertificate
          membershipSku
          serviceSku
          monogram
          monogramMessage
          returnPolicyMessage
          countryRestrictions
          mattressFeeLocation
          preBillMessage
        }
        __typename
        productId
        quantity
      }
      __typename
      minInventory
      skuInventory {
        fullSkuId
        atgSkuId
        inventoryCode
        inventoryOnHand
        inventoryStatus
        inventoryRemaining
        inventoryMessage
        itemsInStockMessage
        lineItemMessage
        needPostalCode
        postalCodeSpecific
        preOrder
        dateString
        adapter
        failureMessage
      }
      isSPO
      multiSkuSpoPreBillMessage
      multiSkuSpoReturnMessage
      multiSkuComponentsDeliveryInfo {
        fullSkuId
        postalCode
        needPostalCode
        deliveryEstimateStatus
        deliveryEstimate
        freightExempt
        shippingSurcharge
        shippingSurchargeAmount
        freightExemptMessage
        deliveryStateMessage
      }
    }
  }
`;
