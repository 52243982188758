import gql from "graphql-tag";
import { PriceRangeDisplay } from "graphql-client/fragments/price-range-display";

export const queryProducts = gql`
  query Products(
    $productIds: [String!]!
    $filter: String
    $categoryId: String
    $userType: String
    $siteId: String
    $currencyCode: String
    $measureSystem: String
    $locale: String
  ) {
    products(
      productIds: $productIds
      filter: $filter
      userType: $userType
      categoryId: $categoryId
      siteId: $siteId
      currencyCode: $currencyCode
      measureSystem: $measureSystem
      locale: $locale
    ) {
      id
      displayName
      imageUrl
      isActive
      newProduct
      customProduct
      saleInfo {
        percentSaleSkus
        showSaleMessage
        memberSavings {
          memberSavingsMin
          memberSavingsMax
        }
      }
      priceRangeDisplay {
        ...PriceRangeDisplay
      }
      priceRangeMultiSkuDisplay {
        ...PriceRangeDisplay
      }
      rhr
      pgCropRules
      pgCrop
      uxAttributes {
        productType
        triggerSwatchImage
        giftCert
        membershipProduct
      }
    }
  }
  ${PriceRangeDisplay}
`;
