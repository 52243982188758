import { UpsellPriceRangeDisplay } from "graphql-client-product/fragments/upsell-price-range-display";
import gql from "graphql-tag";

export const queryUpsSellProducts = gql`
  query UpsellProducts(
    $productId: String
    $productIds: [String!]
    $locale: String
    $measureSystem: String
    $currencyCode: String
    $siteId: String
    $userType: String
  ) {
    upsellProducts(
      productId: $productId
      productIds: $productIds
      locale: $locale
      measureSystem: $measureSystem
      currencyCode: $currencyCode
      siteId: $siteId
      userType: $userType
    ) {
      upsellProducts {
        id
        type
        metaProduct
        displayName
        imageUrl
        galleryDescription
        newProduct
        template
        pgCrop
        pgCropRules
        uxAttributes {
          productType
          triggerSwatchImage
          giftCert
        }
        priceRangeDisplay {
          ...UpsellPriceRangeDisplay
        }
        priceRangeMultiSkuDisplay {
          ...UpsellPriceRangeDisplay
        }
        saleInfo {
          percentSaleSkus
          showSaleMessage
          memberSavings {
            memberSavingsMin
            memberSavingsMax
          }
        }
        index
        rangeId
        parentCategoryId
        rhr
        onSale
        metaProductComponent
      }
      collectionName
      componentTitle
    }
  }
  ${UpsellPriceRangeDisplay}
`;
