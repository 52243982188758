import gql from "graphql-tag";

export const queryProductImage = gql`
  query ProductImage(
    $productId: String!
    $imageKey: String
    $fullSkuId: String
    $swatchIds: [String!]
    $selectedOptionIds: [String!]
    $siteId: String
    $locale: String
  ) {
    productImage(
      productId: $productId
      imageKey: $imageKey
      fullSkuId: $fullSkuId
      swatchIds: $swatchIds
      selectedOptionIds: $selectedOptionIds
      siteId: $siteId
      locale: $locale
    ) {
      productId
      imageUrl
    }
  }
`;
