import gql from "graphql-tag";
import { Wishlist } from "graphql-client/fragments/wishlist";

export const queryGetWishlist = gql`
  query GetWishlist($email: String!, $userId: String!) {
    getWishlist(email: $email, userId: $userId) {
      ...Wishlist
    }
  }
  ${Wishlist}
`;

export const moveFromWishlistToCartMutation = gql`
  mutation moveFromWishlistToCart(
    $lineItemId: String!
    $email: String!
    $guest: CreateWishlistInput!
    $createCartInfo: CreateCartInfoInput
  ) {
    moveFromWishlistToCart(
      email: $email
      lineItemId: $lineItemId
      guest: $guest
      createCartInfo: $createCartInfo
    ) {
      ...Wishlist
    }
  }
  ${Wishlist}
`;

export const moveAllFromWishlistToCartMutation = gql`
  mutation moveAllFromWishlistToCart(
    $email: String!
    $guest: CreateWishlistInput!
    $createCartInfo: CreateCartInfoInput
  ) {
    moveAllFromWishlistToCart(
      email: $email
      guest: $guest
      createCartInfo: $createCartInfo
    ) {
      ...Wishlist
    }
  }
  ${Wishlist}
`;

export const updateWishlistItemQuantity = gql`
  mutation updateWishlistItemQuantity(
    $email: String!
    $lineItemId: String!
    $quantity: Float!
    $guest: CreateWishlistInput!
  ) {
    updateWishlistItemQuantity(
      email: $email
      lineItemId: $lineItemId
      quantity: $quantity
      guest: $guest
    ) {
      ...Wishlist
    }
  }
  ${Wishlist}
`;

export const removeItemFromWishlist = gql`
  mutation removeItemFromWishlist(
    $email: String!
    $lineItemId: String!
    $guest: CreateWishlistInput!
  ) {
    removeItemFromWishlist(
      email: $email
      lineItemId: $lineItemId
      guest: $guest
    ) {
      ...Wishlist
    }
  }
  ${Wishlist}
`;

export const addItemToWishlistMutation = gql`
  mutation addItemsToWishlist(
    $email: String!
    $lineItems: [LineItemInput!]!
    $guest: CreateWishlistInput!
  ) {
    addItemsToWishlist(email: $email, lineItems: $lineItems, guest: $guest) {
      ...Wishlist
    }
  }
  ${Wishlist}
`;

// Remove this after finishing the migration from ATG
export const addItemsToWishlistATG = gql`
  mutation addItemsToWishlistATG(
    $items: [WishlistAddItemInput!]!
    $sessionConfirmation: String!
  ) {
    addItemsToWishlistATG(
      items: $items
      sessionConfirmation: $sessionConfirmation
    ) {
      statusCode
    }
  }
`;

// Remove this after finishing the migration from ATG
export const switchWishlistRegion = gql`
  mutation SwitchWishlistRegion(
    $email: String!
    $userId: String!
    $country: String!
  ) {
    switchWishlistRegion(email: $email, userId: $userId, country: $country) {
      ...Wishlist
    }
  }
  ${Wishlist}
`;
