import gql from "graphql-tag";

export interface Message {
  text: string;
  isHidden?: null | boolean;
}

export type SaleContextFilter = "sale" | "finalSale" | null;

export interface App {
  isLoading: boolean;
  message: Message | null;
  saleContextFilter: SaleContextFilter;
  postalCode: string;
  openSearchDrawer: boolean;
}

export interface AppData {
  app: App;
}

export const initialAppData: AppData = {
  app: {
    isLoading: false,
    message: null,
    saleContextFilter: null,
    postalCode: "",
    openSearchDrawer: false
  }
};

export const queryApp = gql`
  query App {
    app @client {
      isLoading
      message {
        text
        variant
        isHidden
      }
      saleContextFilter
      postalCode
      openSearchDrawer
    }
  }
`;

export const queryLoading = gql`
  query App {
    app @client {
      isLoading
    }
  }
`;
