import gql from "graphql-tag";

export const queryGetWistia = gql`
  query GetWistia($WistiaInput: WistiaInput!) {
    getWistia(WistiaInput: $WistiaInput) {
      wistiaJson
      wistiaThumbnail
    }
  }
`;

export const queryGetWistias = gql`
  query GetWistias($WistiasInput: WistiasInput!) {
    getWistias(WistiasInput: $WistiasInput) {
      wistiaId
      aspectRatio
    }
  }
`;
