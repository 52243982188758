import gql from "graphql-tag";

export const mutationSendScheduleAppointmentEmail = gql`
  mutation SendScheduleAppointmentEmail(
    $input: ScheduleAppointmentInput!
    $sessionConfirmation: String!
  ) {
    sendScheduleAppointmentEmail(
      input: $input
      sessionConfirmation: $sessionConfirmation
    ) {
      statusCode
    }
  }
`;
