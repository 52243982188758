import gql from "graphql-tag";
import { LineItemRelatedproducts } from "graphql-client/fragments/product-line-item";
import { CoreRelatedproductFields } from "graphql-client/fragments/base-product-fields";
import { PersonalizeInfo } from "graphql-client/fragments/product";

export const queryRelatedProducts = gql`
  query RelatedProductsQuery(
    $productIds: [String!]!
    $filter: String
    $currencyCode: String
    $measureSystem: String
    $locale: String
    $siteId: String
    $categoryId: String
    $userType: String
  ) {
    relatedProductsQuery(
      productIds: $productIds
      filter: $filter
      currencyCode: $currencyCode
      measureSystem: $measureSystem
      locale: $locale
      siteId: $siteId
      categoryId: $categoryId
      userType: $userType
    ) {
      __typename
      ...CoreRelatedproductFields
      personalizeInfo {
        ...PersonalizeInfo
      }
      productLineItem(filter: $filter) {
        ...LineItemRelatedproducts
      }
    }
  }
  ${CoreRelatedproductFields}
  ${PersonalizeInfo}
  ${LineItemRelatedproducts}
`;
