import gql from "graphql-tag";

export const Swatch = gql`
  fragment Swatch on ProductSwatch {
    __typename
    swatchId
    title
    sortPriority # Unused
    swatchGroupIndex # Unused
    swatchGroupName
    details # FIXME: Unused (seems important?)
    colorize # Unused
    imageUrl
    primaryOptionId # Unused
    secondaryOptionId # Unused
    featuredPallete
    relatedSwatchBeans {
      swatchId
      title
      sortPriority # Unused
      swatchGroupIndex # Unused
      details # FIXME: Unused (seems important?)
      colorize # Unused
      imageUrl
      primaryOptionId # Unused
      secondaryOptionId # Unused
      optionCopy
      options {
        id
        label
        optionType
        sortPriority
      }
      # FIXME: Unused
      swatchImageSm {
        url
        width
        height
      }
      swatchImageLg {
        url
        width
        height
      }
    }
    optionCopy
    options {
      id
      label
      optionType
      sortPriority
    }
    # FIXME: Unused
    swatchImageSm {
      url
      width
      height
    }
    swatchImageLg {
      url
      width
      height
    }
    onSale
  }
`;

export const SwatchGroup = gql`
  fragment SwatchGroup on ProductSwatchGroup {
    __typename
    swatchGroupName
    groupMaterial
    swatchGroupIndex # Unused
    sortPriority
    stockedSwatches {
      ...Swatch
    }
    customSwatches {
      ...Swatch
    }
  }
  ${Swatch}
`;

export const PersonalizeInfo = gql`
  fragment PersonalizeInfo on ProductPersonalizeInfo {
    __typename
    description
    features
    feeLow # Unused
    feeHigh # Unused
    monogrammable
    personalizable
    waiveMonogramFee
    # Unused
    styles {
      image
      previewImage
      displayName
      id
      minLength
      maxLength
      numberOfLines
    }
    # Unused
    fonts {
      image
      previewImage
      displayName
      id
      borders {
        image
        minLength
        maxLength
        numberOfLines
        id
        displayName
      }
    }
    # Unused
    colors {
      image
      id
      displayName
    }
  }
`;
