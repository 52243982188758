import gql from "graphql-tag";
import { BaseProductFields } from "graphql-client/fragments/base-product-fields";
import { PriceRangeDisplay } from "graphql-client/fragments/price-range-display";
import { LineItem } from "graphql-client/fragments/product-line-item";
import { PersonalizeInfo } from "graphql-client/fragments/product";

export const queryBaseProduct = gql`
  query BaseProduct(
    $productId: String!
    $categoryId: String
    $filter: String
    $userType: String
    $siteId: String
    $currencyCode: String
    $measureSystem: String
    $locale: String
  ) {
    product(
      productId: $productId
      categoryId: $categoryId
      filter: $filter
      userType: $userType
      siteId: $siteId
      currencyCode: $currencyCode
      measureSystem: $measureSystem
      locale: $locale
    ) {
      __typename
      ...BaseProductFields
    }
  }
  ${BaseProductFields}
`;

export const queryBaseProductWithRelatedProducts = gql`
  query BaseProductWithRelatedProducts(
    $productId: String!
    $categoryId: String
    $filter: String
    $userType: String
    $siteId: String
    $measureSystem: String
    $locale: String
  ) {
    product(
      productId: $productId
      categoryId: $categoryId
      filter: $filter
      userType: $userType
      siteId: $siteId
      measureSystem: $measureSystem
      locale: $locale
    ) {
      __typename
      ...BaseProductFields
      relatedProducts {
        __typename
        ...BaseProductFields
        personalizeInfo {
          ...PersonalizeInfo
        }
        productLineItem(filter: $filter) {
          ...LineItem
        }
      }
      upsellProducts {
        upsellProducts {
          id
          type
          displayName
          imageUrl
          galleryDescription
          newProduct
          template
          pgCrop
          pgCropRules
          priceRangeDisplay {
            rangeType
            isUnavailable
            isSale
            showMemberPrice
            listPrices
            salePrices
            memberPrices
            salePriceLabel
            isRetail
            isCustomProduct
            currencySymbol
            priceMessage
            priceMessagePrice
            listPriceLabel
            memberPriceLabel
            strikePriceLabel
          }
        }
      }
    }
  }
  ${BaseProductFields}
  ${PriceRangeDisplay}
  ${PersonalizeInfo}
  ${LineItem}
`;

export const queryRideAlongProduct = gql`
  query BaseProduct(
    $productId: String!
    $categoryId: String
    $filter: String
    $userType: String
    $siteId: String
    $currencyCode: String
    $measureSystem: String
  ) {
    product(
      productId: $productId
      categoryId: $categoryId
      filter: $filter
      userType: $userType
      siteId: $siteId
      currencyCode: $currencyCode
      measureSystem: $measureSystem
    ) {
      __typename
      ...BaseProductFields
      personalizeInfo {
        ...PersonalizeInfo
      }
      productLineItem(filter: $filter) {
        ...LineItem
      }
    }
  }
  ${BaseProductFields}
  ${PersonalizeInfo}
  ${LineItem}
`;
