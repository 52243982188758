import gql from "graphql-tag";
import { CatalogNavigationFragment } from "graphql-client/fragments/catalog-navigation-fields";

export const querySiteNavigation = gql`
  query SiteNavigation($siteId: String! = "RH", $locale: String) {
    siteNavigation(siteId: $siteId, locale: $locale) {
      id
      targetUrl
      displayName
      uxAttributes {
        selectorId
      }
      childCategories {
        id
        targetUrl
        displayName
        uxAttributes {
          selectorId
        }
      }
    }
  }
`;

export const queryCatalogNavigation = gql`
  query CatalogNavigation(
    $filter: String
    $siteId: String! = "RH"
    $mobile: Boolean = true
    $locale: String
    $countryCode: String
    $nextgenPg: String
  ) {
    catalogNavigation(
      filter: $filter
      siteId: $siteId
      mobile: $mobile
      locale: $locale
      countryCode: $countryCode
      nextgenPg: $nextgenPg
    ) {
      id
      targetUrl
      displayName
      linkType
      siteId
      uxAttributes {
        selectorId
      }
      childCategories {
        id
        targetUrl
        displayName
        navImage
        linkType
        siteId
        uxAttributes {
          selectorId
        }
        childCategories {
          id
          targetUrl
          navImage
          displayName
          linkType
          siteId
          uxAttributes {
            selectorId
          }
          childCategories {
            id
            targetUrl
            displayName
            linkType
            siteId
            uxAttributes {
              selectorId
            }
            childCategories {
              id
              targetUrl
              displayName
              linkType
              siteId
              uxAttributes {
                selectorId
              }
            }
          }
        }
      }
    }
  }
`;

export const querySaleCatalogNavigation = gql`
  query SaleCatalogNavigation(
    $filter: String
    $siteId: String! = "RH"
    $mobile: Boolean = true
    $locale: String
    $countryCode: String
    $nextgenPg: String
  ) {
    saleCatalogNavigation(
      filter: $filter
      siteId: $siteId
      mobile: $mobile
      locale: $locale
      countryCode: $countryCode
      nextgenPg: $nextgenPg
    ) {
      ...CatalogNavigationFragment
    }
  }
  ${CatalogNavigationFragment}
`;

export const queryWORHMenu = gql`
  query WORHMenu($categoryId: String! = "cat25070049", $locale: String) {
    worhMenu(categoryId: $categoryId, locale: $locale) {
      id
      targetUrl
      displayName
      navImage
      linkType
      uxAttributes {
        selectorId
      }
      childCategories {
        id
        targetUrl
        displayName
        navImage
        linkType
        uxAttributes {
          selectorId
        }
        childCategories {
          id
          targetUrl
          displayName
          navImage
          linkType
          uxAttributes {
            selectorId
          }
          childCategories {
            id
            targetUrl
            displayName
            navImage
            linkType
            uxAttributes {
              selectorId
            }
          }
        }
      }
    }
  }
`;
