import gql from "graphql-tag";
import { PostalCodeValidation } from "../fragments/postalcode-validation-fields";
import { AddressValidation, AVSAddressValidation } from "../fragments/address";

export const queryGetAddressListByUser = gql`
  query GetAddressListByUser($userId: String!) {
    getAddressListByUser(userId: $userId) {
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      userId
      additionalAddressInfo
      additionalStreetInfo
      apartment
      building
      city
      company
      country
      department
      email
      fax
      firstName
      key
      lastName
      legacyId
      mobile
      phone
      poBox
      postalCode
      region
      salutation
      state
      status
      streetName
      streetNumber
      title
      isDefault
    }
  }
`;

export const removeAddressFromUser = gql`
  mutation removeAddressFromUser($userId: String!, $addressId: String!) {
    removeAddressFromUser(userId: $userId, addressId: $addressId) {
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      userId
      additionalAddressInfo
      additionalStreetInfo
      apartment
      building
      city
      company
      country
      department
      email
      fax
      firstName
      key
      lastName
      legacyId
      mobile
      phone
      poBox
      postalCode
      region
      salutation
      state
      status
      streetName
      streetNumber
      title
      isDefault
    }
  }
`;

export const addAddressToUser = gql`
  mutation addAddressToUser($userId: String!, $address: UserAddAddressInput!) {
    addAddressToUser(userId: $userId, address: $address) {
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      userId
      additionalAddressInfo
      additionalStreetInfo
      apartment
      building
      city
      company
      country
      department
      email
      fax
      firstName
      key
      lastName
      legacyId
      mobile
      phone
      poBox
      postalCode
      region
      salutation
      state
      status
      streetName
      streetNumber
      title
      isDefault
    }
  }
`;

export const editUserAddress = gql`
  mutation editUserAddress(
    $userId: String!
    $addressId: String!
    $address: UserAddAddressInput!
  ) {
    editUserAddress(userId: $userId, addressId: $addressId, address: $address) {
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      userId
      additionalAddressInfo
      additionalStreetInfo
      apartment
      building
      city
      company
      country
      department
      email
      fax
      firstName
      key
      lastName
      legacyId
      mobile
      phone
      poBox
      postalCode
      region
      salutation
      state
      status
      streetName
      streetNumber
      title
      isDefault
    }
  }
`;
export const setDefaultAddress = gql`
  mutation setDefaultAddress(
    $userId: String!
    $addressId: String!
    $country: String!
  ) {
    setDefaultAddress(
      userId: $userId
      addressId: $addressId
      country: $country
    ) {
      createdBy
      createdDate
      id
      lastModifiedBy
      lastModifiedDate
      userId
      additionalAddressInfo
      additionalStreetInfo
      apartment
      building
      city
      company
      country
      department
      email
      fax
      firstName
      key
      lastName
      legacyId
      mobile
      phone
      poBox
      postalCode
      region
      salutation
      state
      status
      streetName
      streetNumber
      title
      isDefault
    }
  }
`;

export const queryVerifyAddresses = gql`
  query VerifyAddresses($addresses: AddressValidationListInput!) {
    verifyAddresses(addresses: $addresses) {
      ...AddressValidation
    }
  }
  ${AddressValidation}
`;

export const queryAVSVerifyAddresses = gql`
  query AVSVerifyAddresses(
    $addresses: AddressValidationListInput!
    $email: String
    $firstName: String
    $lastName: String
    $locale: String
  ) {
    AVSVerifyAddresses(
      addresses: $addresses
      email: $email
      firstName: $firstName
      lastName: $lastName
      locale: $locale
    ) {
      ...AVSAddressValidation
    }
  }
  ${AVSAddressValidation}
`;

export const queryValidateListOfMinimalAddresses = gql`
  query ValidateListOfMinimalAddresses(
    $addresses: AddressValidationListInput!
  ) {
    validateListOfMinimalAddresses(addresses: $addresses) {
      ...AddressValidation
    }
  }
  ${AddressValidation}
`;

export const queryValidatePostalCodeByCountryCode = gql`
  query ValidatePostalCodeByCountryCode(
    $countryCode: String!
    $postalCode: String!
  ) {
    validatePostalCodeByCountryCode(
      countryCode: $countryCode
      postalCode: $postalCode
    ) {
      ...PostalCodeValidation
    }
  }
  ${PostalCodeValidation}
`;
